<!-- This modal is imported into feature-view.vue as a tab. This tab has a search bar that performs an API search based on a key/value pair to bring back applicable promotions and link them to a feature-->
<template>
  <v-container class="ma-0 pa-0" fluid v-resize="onResize">
    <v-data-table
      :headers="headers"
      :height="tableHeight"
      :items="items"
      loading-text="Searching Available Data...Please Wait"
      :loading="loading"
      class="rounded-0"
      fixed-header
      hide-default-footer
      group-by="promo_item_group.id"
      item-key="id"
      loader-height="5"
      no-data-text="No Data to Display"
      dense
      :items-per-page="pageSize">

      <template #top>
        <v-divider/>
          <v-row align="center" dense>
            <v-btn-toggle class="ml-3 my-2" dense mandatory v-model="toggle">
              <v-tooltip top>
                <template #activator="{ on: promoItemActivator }">
                  <v-btn :class="isPromoSearch ? 'green' : ''" v-on="promoItemActivator">
                    <v-icon :color="isPromoSearch ? 'white' : ''">mdi-currency-usd</v-icon>
                  </v-btn>
                </template>
                <span>Promotional Item</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on: nonPromoItemActivator }">
                  <v-btn :class="!isPromoSearch ? 'red' : ''" v-on="nonPromoItemActivator">
                    <v-icon :color="!isPromoSearch ? 'white' : ''">mdi-currency-usd-off</v-icon>
                  </v-btn>
                </template>
                <span>Non-Promotional Item</span>
              </v-tooltip>
            </v-btn-toggle>
            <v-col md="2" class="ml-1 my-2">
              <v-select :class="body" placeholder="Search By" append-icon=":" :items="searchParams"
                  item-text="description" return-object dense hide-details :menu-props="{ closeOnClick: true, bottom: true, offsetY: true }" v-model="search.key" />
            </v-col>
            <v-col md="3" class="ml-1 my-2">
              <v-text-field :class="body" @keydown.enter="isPromoSearch ? promoItemLookup() : nonPromoItemLookup()"
                :placeholder="search.key ? `Search By ${search.key.description}` : 'Select Search Field'" outlined dense hide-details
                background-color="input" v-model="search.value" >
              </v-text-field>
            </v-col>
            <v-tooltip top> 
              <template #activator="{ on: searchActivator }">
                <v-btn class="ml-1 my-2" color="primary" v-on="searchActivator" @click="isPromoSearch ? promoItemLookup() : nonPromoItemLookup()">
                  <v-icon class="white--text">mdi-magnify</v-icon>
                </v-btn>
              </template>
              <span>{{isPromoSearch ? 'Promotional Search' : 'Non Promotional Search'}}</span>
            </v-tooltip>
            <v-spacer/>
            <v-btn 
              v-if='isPromoSearch' 
              class="success mr-5 pr-5"
              :disabled="!pendingAdditions"
              @click.stop='linkPromo()'>
              ADD
            </v-btn>
          </v-row>
      </template>

      <template #[`group.header`]="{ group, items, headers}">
        <td v-if="isPromoSearch" :colspan="headers.length">
          <v-row align="center">
            <v-col class="pl-4 mr-0 pr-0" dense cols='1'>
              <v-icon v-if="feature.promo_item_group_ids.includes(group)" color="success">
                mdi-check
              </v-icon>
              <template v-else>
                  <v-checkbox
                  v-model="pendingAddItems"
                  color="primary"
                  class="my-auto mx-0"
                  hide-details
                  :value="group"
                  :ripple="false">
                </v-checkbox>
              </template>
            </v-col>
            <v-col style="white-space:nowrap;">
              <span>
                <b>Promo Item Group</b><br>
                {{items[0].promo_item_group.name}}
              </span>
            </v-col>
             <v-col style="white-space:nowrap;">
              <span>
                <b>Promo Name</b><br>
              </span>
              <span v-if='items[0].promotion.name'>
                {{items[0].promotion.name}}
              </span>
              <span v-else>N/A</span>
            </v-col>
             <v-col>
              <span>
                <b>Promo Type</b><br>
              </span>
              <span v-if='items[0].kv_name'>
                {{items[0].kv_name}}
              </span>
              <span v-else>N/A</span>
            </v-col>
             <v-col>
              <span>
                <b>Promo Start Date</b><br>
              </span>
              <span v-if='items[0].promotion.start_dt'>
                {{items[0].promotion.start_dt}}
              </span>
              <span v-else>N/A</span>
            </v-col>
            <v-col>
              <span>
                <b>Promo End Date</b><br>
              </span>
              <span v-if='items[0].promotion.end_dt'>
                {{items[0].promotion.end_dt}}
              </span>
              <span v-else>N/A</span>
            </v-col>
            <v-col align="right">
              <v-tooltip top>
                <template #activator="{ on: openLinkActivator }">
                  <v-btn small icon v-on="openLinkActivator"
                    class="mr-2"
                    @click='openLink(items[0])'>
                    <v-icon>
                      mdi-open-in-new
                    </v-icon>
                  </v-btn>
                </template>
                <span>Promotion Portal</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </td>
      </template>

      <!-- <template #[`header.data-table-select`]="{ on, props }">
        <v-simple-checkbox dark v-if="isPromoSearch " :ripple="false" v-on="on" v-bind="props" />
      </template> -->

      <template #[`item.amount_off`]="{ item }">
        <span v-if="item.amount_off">${{formatCurrencyDisplay(item.amount_off)}}</span>
        <span v-else>-</span>
      </template><template #[`item.promotion_price`]="{ item }">
        <span v-if="item.promotion_price">${{formatCurrencyDisplay(item.promotion_price)}}</span>
        <span v-else>-</span>

      </template><template #[`item.rebate_amount`]="{ item }">
        <span v-if="item.promo_item_group.rebate_amount">${{formatCurrencyDisplay(item.promo_item_group.rebate_amount)}}</span>
        <span v-else-if="item.promotion.rebate_amount">${{formatCurrencyDisplay(item.promotion.rebate_amount)}}</span>
        <span v-else>-</span>
      </template>

      <template #[`item.item.cost`]="{ item }">
        <span v-if="item.item.cost">${{formatCurrencyDisplay(item.item.cost)}}</span>
        <span v-else>-</span>
      </template>

      <template #[`item.retail_price`]="{ item }">
        <span v-if="item.promo_item_group.retail_price">${{formatCurrencyDisplay(item.promo_item_group.retail_price)}}</span>
        <span v-else-if="item.promotion.retail_price">${{formatCurrencyDisplay(item.promotion.retail_price)}}</span>
        <span v-else>-</span>
      </template>

      <template #[`item.billback_allowance`]="{ item }">
        <span v-if="item.accrual_amt">${{formatCurrencyDisplay(item.accrual_amt)}}</span>
        <span v-else-if="item.promo_item_group.billback_allowance">${{formatCurrencyDisplay(item.promo_item_group.billback_allowance)}}</span>
        <span v-else-if="item.promotion.billback_allowance">${{formatCurrencyDisplay(item.promotion.billback_allowance)}}</span>
        <span v-else>-</span>
      </template>

      <template #[`item.amap_allowance`]="{ item }">
        <span v-if="item.promo_item_group.amap_allowance">${{formatCurrencyDisplay(item.promo_item_group.amap_allowance)}}</span>
        <span v-else-if="item.promotion.amap_allowance">${{formatCurrencyDisplay(item.promotion.amap_allowance)}}</span>
        <span v-else>-</span>
      </template>

      <template #[`item.early_buy_allowance`]="{ item }">
        <span v-if="item.promo_item_group.early_buy_allowance">${{formatCurrencyDisplay(item.promo_item_group.early_buy_allowance)}}</span>
        <span v-else-if="item.promotion.early_buy_allowance">${{formatCurrencyDisplay(item.promotion.early_buy_allowance)}}</span>
        <span v-else>-</span>
      </template>
      <template #[`item.item.sale_price`]="{ item }">
        <span v-if="item.item.sale_price">${{formatCurrencyDisplay(item.item.sale_price)}}</span>
        <span v-else>-</span>
      </template>

      <template #[`item.promo_info`]>
        <span>-</span>
      </template>
       <template v-slot:[`item.item.cost`]="{ item }">
        <v-tooltip top v-if="(item.item.future_cost == item.item.cost) && (item.item.future_cost_date <= selectedAd.ad_end_date)" >
          <template v-slot:activator="{ on }">
            <span v-on="on" class="primary--text font-weight-bold">
              ${{formatCurrencyDisplay(item.item.cost)}}
            </span>
          </template>
          This item has a cost change on {{ $dateConfig.formatDate(item.item.future_cost_date) }} <br>
          The new cost of ${{formatCurrencyDisplay(item.item.cost)}} will be used on this ad
        </v-tooltip>
        <span v-else> ${{formatCurrencyDisplay(item.item.current_cost)}} </span>
      </template>

       <template v-slot:[`item.cost`]="{ item }"><!-- this is for the non promo items-->
        <v-tooltip top v-if="(item.future_cost != item.cost) && (item.future_cost_date <= selectedAd.ad_end_date)" >
          <template v-slot:activator="{ on }">
            <span v-on="on" class="primary--text font-weight-bold">
              ${{formatCurrencyDisplay(item.future_cost)}}
            </span>
          </template>
          This item has a cost change on {{$dateConfig.formatDate(item.future_cost_date)}} <br>
          The new cost of ${{formatCurrencyDisplay(item.future_cost)}} will be used on this ad
        </v-tooltip>
        <span v-else> ${{formatCurrencyDisplay(item.cost)}} </span>
      </template>

      <template #[`item.unit_cost`]="{ item }">
          <span v-if="item.item.qty_per_case">  ${{formatCurrencyDisplay(calcUnitCost(item))}} </span>
          <span v-else> - </span>
        </template>


      <template #[`item.start_dt`]="{ item }">
        {{formatDateDisplay(item.start_dt)}}
      </template>

      <template #[`item.end_dt`]="{ item }">
        {{formatDateDisplay(item.end_dt)}}
      </template>

      <template #[`item.count_price`]="props">
        <v-row dense style="height:60px;">
          <v-spacer/>
          <v-col cols="2">
            <v-menu
              v-model="editingQuantity"
              :close-on-content-click="false"
              :close-on-click="false"
              bottom
              right
              absolute
              nudge-top="-12px"
              nudge-left="16px"
              offset-y
              max-width="300px">
              <template v-slot:activator="{ on, attrs }">
                <div class="edit-menu-container"
                  :style="!nonPromoItems.includes(props.item.item_id) ? 'cursor: pointer' : 'cursor: default'"
                  v-on="!nonPromoItems.includes(props.item.item_id) ? on : null"
                  v-bind="attrs"
                  @click="!nonPromoItems.includes(props.item.item_id) ? open(props.item) : null">
                  <span>
                    <div class="text-caption ma-0 pa-0">Qty</div>
                    <v-btn v-if="!nonPromoItems.includes(props.item.item_id)" class="mx-0 pa-0" depressed small>
                      {{ props.item.multiplier ? props.item.multiplier : '-' }}
                    </v-btn>
                    <div v-else class="ml-0 pa-0">
                      {{ props.item.multiplier ? props.item.multiplier : '-' }}
                    </div>
                  </span>
                </div>
              </template>
              <v-card class="pt-2 px-4" style="z-index: 97" tile flat v-if="editItem && editItem.item_id === props.item.item_id">
                <div style="margin-top: 8px">
                  <span class="subtitle-2">Edit Quantity</span>
                </div>
                  <div class="qty-controls ml-13">
                    <v-btn 
                      icon 
                      x-small
                      class="my-auto py-0 my-0"
                      fab
                      dense
                      color="primary"
                      :disabled="editItem.multiplier === 1"
                      @click="decreaseQty">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                    <v-text-field
                      ref="multiplier"
                      v-model="editItem.multiplier"
                      class="pt-2 mt-0"
                      :rules="[
                        v => !!v || 'Count Required',
                        v => !(v < 0) || 'Cannot have negative quantity'
                      ]"
                      placeholder="Qty"
                      min="1"
                      single-line
                      autofocus
                      hide-spin-buttons
                      type="number">
                    </v-text-field>
                    <v-btn
                      icon 
                      x-small
                      class="my-auto py-0 my-0"
                      fab
                      dense
                      color="primary"
                      @click="increaseQty">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                <v-card-actions class="pt-0 px-0">
                  <v-spacer/>
                  <v-btn
                    text
                    small
                    @click="cancel">
                    Cancel
                  </v-btn>
                  <v-btn
                    small
                    color="primary"
                    :loading="loading"
                    :disabled="!editItem.multiplier || editItem.multiplier < 0"
                    @click="save('multiplier', props.item)">
                    Save
                  </v-btn>
                  <v-btn
                    small
                    color="accent"
                    :loading="loading"
                    :disabled="!editItem.multiplier || editItem.multiplier < 0"
                    @click="applyAll('multiplier')">
                    Apply All
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="1" class="ml-3 mt-4">
            <v-icon  small>mdi-at</v-icon>
          </v-col>
          <v-col cols="4">
            <v-menu
              v-model="editingSalePrice"
              :close-on-content-click="false"
              :close-on-click="false"
              bottom
              right
              absolute
              nudge-top="-12px"
              nudge-left="16px"
              offset-y
              max-width="300px">
              <template v-slot:activator="{ on, attrs }">
                <div class="edit-menu-container"
                  :style="!nonPromoItems.includes(props.item.item_id) ? 'cursor: pointer' : 'cursor: default'"
                  v-on="!nonPromoItems.includes(props.item.item_id) ? on : null"
                  v-bind="attrs"
                  @click="!nonPromoItems.includes(props.item.item_id) ? open(props.item) : null">
                  <span class="mx-0 pa-0">
                    <div class="text-caption ma-0 pa-0">Sale Price</div>
                    ${{ formatCurrencyDisplay(props.item.sale_price) }}
                  </span>
                  <v-icon class="ml-0 mt-4 pa-0" small v-if="!nonPromoItems.includes(props.item.item_id)">mdi-pencil</v-icon>
                </div>
              </template>
              <v-card class="pt-2 px-4" style="z-index: 98" tile flat v-if="editItem && editItem.item_id === props.item.item_id">
                <div style="margin-top: 8px">
                  <span class="subtitle-2">Edit Sale Price</span>
                </div>
                <v-text-field
                  ref="sale_price"
                  class="pt-0 mt-0"
                  v-model="editItem.sale_price"
                  :rules="[isValidPrice, v => !(v < 0) || 'Cannot have negative value.']"
                  autofocus
                  type="number"
                  hide-spin-buttons
                  prefix="$">
                </v-text-field>
                <v-card-actions class="pt-0 px-0">
                  <v-btn
                    text
                    small
                    @click="cancel">
                    Cancel
                  </v-btn>
                  <v-btn
                    small
                    color="primary"
                    :loading="loading"
                    :disabled="!editItem.sale_price || editItem.sale_price < 0"
                    @click="save('sale_price', props.item)">
                    Save
                  </v-btn>
                  <v-btn
                    small
                    color="accent"
                    :loading="loading"
                    :disabled="!editItem.sale_price || editItem.sale_price < 0"
                    @click="applyAll('sale_price')">
                    Apply All
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
          <v-spacer/>
        </v-row>
      </template>

      <template #[`item.net_unit_cost`]="props">
        <v-row dense class="ml-0 pa-0" style="height:60px;">
          <v-col cols="3">
              <v-menu
                v-model="editingNetUnitCost"
                :close-on-content-click="false"
                :close-on-click="false"
                bottom
                absolute
                nudge-top="-12px"
                nudge-left="16px"
                offset-y
                max-width="300px">
                <template v-slot:activator="{ on, attrs }">
                  <div class="edit-menu-container"
                    :style="!nonPromoItems.includes(props.item.item_id) ? 'cursor: pointer' : 'cursor: default'"
                    v-on="!nonPromoItems.includes(props.item.item_id) ? on : null"
                    v-bind="attrs"
                    @click="!nonPromoItems.includes(props.item.item_id) ? open(props.item) : null">
                    <span class="ml-0 mt-4 pa-0">
                      {{ props.item.net_unit_cost ? '$' + formatCurrencyDisplay(props.item.net_unit_cost) : '-' }}
                    </span>
                    <v-icon class="ml-2 mt-4 mb-1 pa-0" small v-if="!nonPromoItems.includes(props.item.item_id)">mdi-pencil</v-icon>
                  </div>
                </template>
                <v-card class="pt-2 px-4" style="z-index: 99" tile flat v-if="editItem && editItem.item_id === props.item.item_id">
                  <div style="margin-top: 8px">
                    <span class="subtitle-2">Edit Net Unit Cost</span>
                  </div>
                  <v-text-field
                    ref="net_unit_cost"
                    class="pt-0 mt-0"
                    v-model="editItem.net_unit_cost"
                    :rules="[isValidPrice, v => !(v < 0) || 'Cannot have negative value.']"
                    autofocus
                    type="number"
                    hide-spin-buttons
                    prefix="$">
                  </v-text-field>
                  <v-card-actions class="pt-0 px-0">
                    <v-spacer/>
                    <v-btn
                      text
                      small
                      @click="cancel">
                      Cancel
                    </v-btn>
                    <v-btn
                      small
                      color="primary"
                      :loading="loading"
                      :disabled="!editItem.net_unit_cost || editItem.net_unit_cost < 0"
                      @click="save('net_unit_cost', props.item)">
                      Save
                    </v-btn>
                    <v-btn
                      small
                      color="accent"
                      :loading="loading"
                      :disabled="!editItem.net_unit_cost || editItem.net_unit_cost < 0"
                      @click="applyAll('net_unit_cost')">
                      Apply All
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
      </template> 

      <template #[`item.gross_profit`]="{ item }">
       <span v-if="item.multiplier && item.sale_price && item.net_unit_cost" >
       {{formatCurrencyDisplay(calcNonPromoGP(item))}}%
       </span>
       <span v-else> - </span>
      </template>

      <template v-slot:[`item.related`]="{ item }">
        <div v-if="item[tenantLinkedProperty]">
          <v-tooltip class="mx-auto" top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                small
                color="primary"
                @click="viewRelatedItems(item)">
                <v-icon class="mx-auto">mdi-view-list</v-icon>
              </v-btn>
            </template>
            <span>Related Items</span>
          </v-tooltip>
        </div>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-btn
          v-if="!nonPromoItems.includes(item.item_id)"
          class="white--text"
          fab 
          depressed
          @click="addItem(item)"
          :disabled="!item.multiplier || !item.sale_price || !item.net_unit_cost"
          color="primary"
          style="height:25px; width:25px;">
          <v-icon small>mdi-plus</v-icon>
        </v-btn>
        <v-icon v-else color="green">
          mdi-check
        </v-icon>
      </template>
    </v-data-table>

    <RelatedItemsModal
      v-if="showRelatedItemsModal"
      v-model="showRelatedItemsModal"
      :item="currentItem"
      :currentItems="feature.non_promo_items"
      :tableItems="items"
      :readOnly="false"
      :itemSearch="true"
      @search="searchItems"
      @cancel="showRelatedItemsModal = false">
    </RelatedItemsModal>

  </v-container>
</template>
<script>
const RelatedItemsModal = () => import('@/components/features/RelatedItemsModal.vue')
import { displayHelpers } from '@/mixins/display-helpers'
import { format } from '@/mixins/format'
import { utils } from '@/mixins/utils'
import { cloneDeep } from 'lodash'
export default {
  name: 'FeatureItemSearch',
  mixins: [displayHelpers, format, utils],
  components: { RelatedItemsModal },
  props: [
    'feature',
    'selectedAd',
    'showInfo'
  ],
  data: () => ({
    store_group_id: '',
    dc_id:'',
    loading: false,
    modal: false,
    itemSearch: null,
    promoInfo: false,
    items: [],
    responsible_party_id: '49b5f50f-6bf8-4315-9366-5d993572277d',
    search: {key:{},value: null },
    selectedGroup: '',
    toggle: 0,
    tableHeight: '',
    offset: true,
    page: 1,
    pageSize: 500,
    pageSizes: [500],
    promoItems:[],
    disableNext: false,
    disablePrevious: true,
    currentItem: null,
    showRelatedItemsModal: false,
    editItem: null,
    editingQuantity: false,
    editingNetUnitCost: false,
    editingSalePrice: false,
    pendingAddItems: []
  }),
  computed: {
    tenantLinkedProperty() {
      return (this.$auth.tenant === 'pricechopper')
        ? 'pce_link_code'
        : 'linked_group'
    },
    isPromoSearch () {
      return (this.toggle === 0)
    },
    nonPromoItems () {
      return this.feature.non_promo_items.flatMap(item => (item?.item_id || []))
    },
    searchParams () {
      if(this.isPromoSearch){
      return [
        { description: 'Item ID', param: 'item_id' },
        { description: 'UPC', param: 'upc' },
        { description: 'PLU', param: 'plu' },
        { description: 'Deal Number', param: 'deal_number' },
        { description: 'Vendor ID', param: 'vendor_id' },
        { description: 'Category', param: 'category' },
      ]
      }
      else{
        return [
        { description: 'Item ID', param: 'item_id' },
        { description: 'UPC', param: 'upc' },
        { description: 'PLU', param: 'plu' },
        { description: 'Description', param: 'description' },
        ]
      }
    },
    headers () {
      let headers = [
        { sortable: false, filterable: false, class: 'accent white--text', show: true },
        { text: 'Item', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'item_id', show: true },
        { text: 'GTIN', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'gtin', show: this.$auth.tenant === 'pricechopper' },
        { text: 'Source', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'source', show: true },
        { text: 'Name', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'description', show: true },
        { text: 'Retail Price', align: 'center', sortable: false, class: 'accent white--text', value: 'count_price', width: '20%', show: true },
        { text: 'Net Unit Cost', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'net_unit_cost', show: true },
        { text: 'Cost', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'cost', show: true },
        { text: 'Pack Size', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'pack_size', show: true },
        { text: 'Gross Profit', groupable: false, align: 'start', sortable: false, class : 'accent white--text', value:'gross_profit', show: true },
        { text: 'Related Items', value: 'related', align: 'center', sortable: false, class: 'accent white--text', show: this.$auth.tenant === 'pricechopper' },
        { text: 'Actions', align: 'center', sortable: false, class: 'accent white--text', value: 'actions', show: true },
      ]
      if (this.isPromoSearch) {
        headers = [
          { sortable: false, filterable: false, class: 'accent white--text', show: true },
          { text: 'Item', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'item.item_id', show: true },
          { text: 'GTIN', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'item.gtin', show: this.$auth.tenant === 'pricechopper' },
          { text: 'Source', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'item.source', show: true },
          { text: 'Name', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'item.description', show: true },
          { text: 'Promotion Price', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'retail_price', show: true },
          { text: 'Scan Allowance', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'rebate_amount', show: true },
          { text: 'Billback Allowance', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'billback_allowance', show: true },
          { text: 'AMAP Allowance', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'amap_allowance', show: true },
          { text: 'Early Buy Allowance', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'early_buy_allowance', show: true },
          { text: 'Retail Price', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'item.sale_price', show: true },
          { text: 'Case Cost', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'item.cost', show: true },
          { text: 'Net Unit Cost', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'unit_cost', show: true },
          { text: 'Pack Size', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'item.pack_size', show: true },
        ]
      }
      return headers.filter(item => item.show)
    },
    classification () {
      return this.$store.getters.classifications.find(c => c.id === this.selectedAd.classification).constant
    },
    pendingAdditions () {
      return this.pendingAddItems.length > 0
    }
  },
  watch: {
    showInfo: {
      immediate: true,
      handler () {
        if (!this.showInfo) {
          this.tableHeight = window.innerHeight - 300
        } else {
          this.tableHeight = window.innerHeight - 430
        }
      }
    },
    isPromoSearch: {
      handler () {
        this.items = []
        this.pendingAddItems = []
        localStorage.setItem('promo_search', this.isPromoSearch)
      }
    }
  },
  created () {
    if (!this.showInfo) {
      this.tableHeight = window.innerHeight - 300
    } else {
      this.tableHeight = window.innerHeight - 430
    }
    this.store_group_id = this.selectedAd.ad_party_id
    this.party_info = JSON.parse(sessionStorage.getItem('ad_party'))
    this.dc_id = this.party_info.dc_id
    this.item_source = this.party_info.distribution_center
    this.search.key.param='item_id'
    this.search.key.description ='Item ID'
    this.$root.$on('refresh-search', () => this.promoItemLookup(true))
  },
  methods: {
    open (item) {
      this.editItem = { ...cloneDeep(item), multiplier: item.multiplier ? item.multiplier : 1 }
    },
    save (key, item) {
      this.closeEditDialogs()
      const valid = this.$refs[key].validate()
      if (valid) {
        item[key] = this.editItem[key]
      }
    },
    cancel () {
      this.editItem = null
      this.closeEditDialogs()
    },
    closeEditDialogs () {
      this.editingQuantity = false
      this.editingNetUnitCost = false
      this.editingSalePrice = false
    },
    applyAll (key) {
      const valid = this.$refs[key].validate()
      if (valid) {
        const newValue = this.editItem[key]
        this.items.forEach(item => {
          if (!this.nonPromoItems.includes(item.item_id)) {
            item[key] = newValue
          }
        })
        this.closeEditDialogs()
      }
    },
    increaseQty() {
      this.editItem.multiplier += 1
    },
    decreaseQty() {
      this.editItem.multiplier -= 1
    },
    searchItems(items) {
      this.showRelatedItemsModal = false
      const item_ids = items.map(item => item.item_id)
      this.nonPromoItemLookup(item_ids)
    },
    viewRelatedItems (item) {
      this.currentItem = item
      this.showRelatedItemsModal = true
    },
    onResize () {
      this.tableHeight = window.innerHeight - 430
    },
    itemRowBackground (item) {
      return !this.isPromoSearch && this.items.indexOf(item) % 2 !== 1 ? 'grey lighten-2' : ''
    },
    async verifySource() {
      try {
        if (this.selectedAd.ad_party_id !== this.party_info.party_id) {
          const res = await this.$Party.getPartyByID(this.selectedAd.ad_party_id)
          this.party_info = res.data
          this.party_info.party_id = this.party_info.id
          const dc_results = await this.getDistributionCenter(this.selectedAd.ad_party_id)
          this.party_info = {...this.party_info, ...dc_results}
          this.dc_id = this.party_info.dc_id
          this.item_source = this.party_info.distribution_center
        }
      } catch(err) {
        this.$store.dispatch('setSnackbar', { status: 'error', text: `${err?.response?.data?.message || err.message}` })
      }
    },
    async nonPromoItemLookup (searchValues = []) {
      await this.verifySource()
      let key = this.search.key?.param
      let value = this.search.value?.trim()
      const item_source = this.party_info.distribution_center
      const params = { source: item_source }
      params[key] = value

      const multiple = (searchValues.length > 0)
      let requests = [this.$Features.nonPromoLookup(params)]
      if (multiple) {
        requests = searchValues.map(val => {
          return this.$Features.nonPromoLookup({ source: item_source, item_id: val })
        })
      }
      let items = []
      this.loading = true
      this.$store.dispatch('setSnackbar', { status: 'success', text: `Searching...` })
      try {
        const { fulfilled, rejected } = await this.getAllSettled(requests, true)
        if (rejected?.length > 0) throw rejected
        const results = fulfilled.flatMap(result => result?.data || [])
        if (results.length > 0) {
          items = results.map(item => {
            item.retail_price = item.sale_price
            item.multiplier = item.multiplier ? item.multiplier : 1
            return item
          })
        }
        if (multiple) {
          this.items = [...this.items, ...items]
        } else {
          this.items = items
        }
        if (this.items.length > 0) {
          this.items = this.items.map(item => this.formatItemPrices(item))
        }
        this.$store.dispatch('setSnackbar', { status: 'success', text: `Showing Results` ,setTimeout: 30})
      } catch (err) {
        console.error(err)
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Search due to ${err?.response?.data?.message || err.message}` })
      } finally {
        this.loading = false
      }
    },
    async promoItemLookup(skipEmpty = false) {
      await this.verifySource()
      let key = this.search.key?.param
      let value = this.search.value?.trim()

      // Skips the search if called when PIG is deleted from promo, and there is no search already entered (prevents a general search)
      if ((value == null || value == "") && skipEmpty)
      { return }

      const params = {
        start_dt: this.selectedAd.ad_start_date,
        end_dt: this.selectedAd.ad_end_date,
        classification: this.classification,
        perishable: this.selectedAd.ad_type.constant === 'PERISHABLE'
      }
      if (key && value) {
        params[key] = value
      }
      let items = []
      this.loading = true
      this.$store.dispatch('setSnackbar', { status: 'success', text: `Searching...`})
      try {
        const UUIDs = [this.store_group_id, this.dc_id]
        const res = await this.$PromoItem.promoItemLookup(UUIDs, params)
        if (res?.data?.length > 0) {
          items = res.data.flatMap(item => {
            if (item.item.source === this.item_source) {
              return item
            }
            return []
          })
        }
        this.items = items
        this.$store.dispatch('setSnackbar', { status: 'success', text: `Showing Results` ,setTimeout: 30})
      } catch (err) {
        console.error(err)
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Search due to ${err?.response?.data?.message || err.message}` })
      } finally {
        this.loading = false
        this.pendingAddItems = []
      }
    },
    linkPromo() {
      this.feature.promo_item_group_ids.push(...this.pendingAddItems)
      this.pendingAddItems = []
      this.update()
    },
    addItem (item) {
      const cost = (item.future_cost && (item.future_cost_date < this.selectedAd.ad_end_date))
        ? item.future_cost
        : item.cost

        const new_item = {
          item_id: item.item_id,
          feature_id: this.feature.id,
          multiplier: item.multiplier,
          sale_price: item.sale_price,
          description: item.description,
          source: this.item_source,
          net_unit_cost: item.net_unit_cost,
          cost,
          retail_price: item.retail_price,
          qty_per_case: item.qty_per_case,
          pack_size: item.pack_size
        }
      this.feature.non_promo_items.unshift(new_item)
      this.update()
    },
    async update () {
      try {
        await this.$Features.updateAdFeature(this.feature.id, this.feature)
        this.$root.$emit('refresh-features')
        this.$root.$emit('get-items')
        this.$store.dispatch('setSnackbar', { status: 'success', text: this.isPromoSearch  ? 'Promotion Item Group(s) Added' : 'Feature Item Added' })
      } catch (err) {
        console.error(err)
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Update Feature due to ${err?.response?.data?.message || err.message}` })
      }
    },
    calcUnitCost(item){
      if (item.accrual_amt){
        return ((item.item.cost -(item.promo_item_group.rebate_amount+item.accrual_amt+item.promo_item_group.amap_allowance+item.promo_item_group.early_buy_allowance))/item.item.qty_per_case)
      }
      else if (item.promo_item_group.amap_allowance || item.promo_item_group.billback_allowance || item.promo_item_group.early_buy_allowance){
        return ((item.item.cost -(item.promo_item_group.rebate_amount+item.promo_item_group.billback_allowance+item.promo_item_group.amap_allowance+item.promo_item_group.early_buy_allowance))/item.item.qty_per_case)
      }
      else{
        return (item.item.cost/item.item.qty_per_case-(item.promo_item_group.rebate_amount+item.promo_item_group.billback_allowance+item.promo_item_group.amap_allowance+item.promo_item_group.early_buy_allowance))
      }
    },
    openLink(pig){
      let link = this.buildPortalUrl('promo')
      link += `/promo?id=${pig.promo_item_group.promo_id}`
      console.log
      window.open(link, 'tab')
    },
    calcNonPromoGP(item){
      const ad_retail = parseFloat(item.sale_price)
      const ad_count = parseFloat(item.multiplier)
      const net_unit_cost = parseFloat(item.net_unit_cost)
      return ((((ad_retail / ad_count)) 
      - net_unit_cost) 
      / ((ad_retail / ad_count)) * 100).toFixed(2) 

    }
  }
}
</script>
<style scoped>
  .active {
    box-shadow: 0px 2px 10px 3px rgba(120,120,120,0.75);
    background: #385F73;
    color: white !important;
  }
  .edit-menu-container {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    grid-column-gap: 0px;
    position: relative;
  }
  .qty-controls {
    display: grid; 
    width: 100%; 
    max-width: 100px; 
    grid-template-columns: auto 50px auto; 
    justify-content: flex-center;
  }
</style>